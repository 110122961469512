import React from "react";
import ReactDOM from "react-dom";

// Do not allow any routes without a redirect to the new site
const env = process.env.REACT_APP_ENV || window.location.host.split(".")[1];
const newHost =
  env === "production" || env === "cms"
    ? "https://cms.koala.io"
    : `https://${env}.cms.koala.io`;

// Unregister the leftover service worker

if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

ReactDOM.render(
  <p>
    Moved to <a href={newHost}>{newHost}</a>
  </p>,
  document.getElementById("root") as HTMLElement
);

window.location.replace(newHost);
